.service {
    max-width: 1116px;
    margin: 140px auto 115px auto;
}

.serviceTitle div {
    color: var(--black, #212121);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.redsig {
    width: 75px;
    height: 4px;
    border-radius: 5px;
    background: var(--Red, #F00);
    margin: 7px auto 50px auto;
    justify-content: center;
}

.serviceItem {
    width: calc((100% * 4)/12 - 15px);
    background: #F8F3F3;
}

.serviceItemAll .serviceItem:nth-child(1) {
    width: calc((100% * 7)/12 - 15px);
}

.serviceItemAll .serviceItem:nth-child(2) {
    width: calc((100% * 5)/12);
    background: #F8F3F3;
}




.serviceIcon {
    padding: 32px 0px 26px 28px;
    display: flex;
}

.serviceIcon img {
    max-width: 24px;
    max-height: 27.925px;
}

.serviceItemTitle {
    color: var(--black, #212121);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin: auto 7px auto 18px;
}

.serviceInfo {
    color: var(--body, #4A4A4A);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    padding: 0 18px 8px 32px;
}

.serviceItemAll {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 15px;
}

.starIconService {
    display: none;
}

.starIconService:first-child {
    display: block !important;
}

.serviceItem ul {
    color: var(--body, #4A4A4A);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    display: none;
    padding: 10px 0 0 50px;
}

.serviceItem .serviceNone0 {
    display: block !important;
}

.serviceIcon .star2 {
    display: none;
}

.serviceIcon .starIconService0 {
    display: block !important;
}

@media (max-width: 1140px) {}

@media (max-width: 992px) {}

@media (max-width: 769px) {
    .serviceItemAll .serviceItem {
        width: 100% !important;
    }

    .service {
        margin: 50px auto 50px auto;
    }

}

@media (max-width: 600px) {
    .serviceTitle div {
        font-size: 25px;
    }

    .serviceInfo {
        font-size: 14px;
    }
}

@media (max-width: 439px) {
    .serviceItemAll .serviceItem:nth-child(2) {
        max-width: 432px;
        height: 638px;
    }
}

@media (max-width: 320px) {
    .serviceItemAll .serviceItem:nth-child(2) {
        height: 700px;
    }

    .serviceItemAll .serviceItem:nth-child(3) {
        height: 384px;
    }

    .serviceItemAll .serviceItem:nth-child(4) {
        height: 384px;
    }

    .service {
        margin: 25px auto 40px auto;
    }

}