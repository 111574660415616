.introduceCompany {
    background-color: #081c2f;
}

.introduce {
    width: 80%;
    display: flex;
    margin: auto;
    padding: 85px 0;
}

.titleIntro {
    color: var(--white, #FFF);
    max-width: 290px;
    max-height: 156px;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
}

.titleRightIntro,
.titleRightIntro2 {
    color: var(--white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
}

.titleRightIntro2 {
    margin: 15px 0 32px 0;
}

.leftIntro {
    width: 100%;
}

.evaluate {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
}

.evaluate-item {
    color: var(--white, #FFF);
}

.number {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    display: flex;
}

.plus1 {
    display: none;
}

.titleNumber {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}


@media (max-width: 1440px) {}

@media (max-width: 992px) {
    .introduce {
        flex-wrap: wrap;
    }

    .titleIntro {
        max-width: unset;
        margin-bottom: 15px;
    }
}

@media (max-width: 768px) {}

@media (max-width: 600px) {
    .evaluate-item {
        margin-right: 10px;
    }

    .leftIntro,
    .rightIntro {
        scale: 0.9;
    }

    .introduce {
        display: block;
    }

    .leftIntro {
        margin-left: 0;
    }

    .introduce {
        padding: 20px 0;
    }

    .titleIntro {
        font-size: 25px;
        margin-bottom: 0;
    }

    .number {
        font-size: 30px;
    }

    .titleRightIntro,
    .titleRightIntro2 {
        font-size: 14px;
    }
}

@media (max-width: 320px) {
    .leftIntro {
        margin-left: 0;
    }

    .evaluate {
        display: block;
    }
}