footer {
    flex-shrink: 0;
    background-color: #081c2f;
}

.footer {
    padding: 0 162px 20px 162px;
    margin: auto;
}

.footerAll {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.logoFooter {
    padding: 62px 0 34px 0;
}

.address {
    display: flex;
    margin-bottom: 20px;
}

.iconFooter {
    background-image: url('../../../../public/img/row.svg');
    width: 32px;
    height: 32px;
    position: relative;
    margin: auto 13px auto 0;
}

.iconFooter img {
    position: absolute;
    color: var(--White, #FFF);
    right: 10px;
    bottom: 10px;
}

.infoFooter {
    max-width: 366px;
    color: var(--White, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}


.infoFooter a,
.infoFooter span {
    color: var(--White, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none
}

.infoFooter a {
    font-weight: 400;
}

.nameFooter {
    color: var(--White, #FFF);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin: 105px 0 16px 0;
}

.abuContent {
    width: 123px;
    height: 36px;
    color: var(--White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.abuContent a {
    text-decoration: none;
    color: var(--White, #FFF);
}

.appContent img {
    max-width: 152px;
    max-height: 58px;
    margin-left: -10px;
    margin-right: 10px;
}

.appContent img:last-child {
    max-width: 121px;
    max-height: 49px;
}

.line {
    max-width: 1343px;
    max-height: 1px;
    background: #E6E6E6;
    margin: 40px auto 25px auto;
}

.botFooter {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 64px;
    padding-bottom: 27px;
    flex-wrap: wrap;
}

.bot {
    color: var(--White, #FFF);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

/* css link app  */

.appContent ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}

.appContent ul li {
    list-style: none;
    margin: 0 15px;
}

.appContent ul li a {
    display: block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 63px;
    background: #666;
    border-radius: 50%;
    font-size: 30px;
    color: #F7F7F7;
    transition: .5s;
}

.appContent ul li a::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: red;
    transition: .5s;
    transform: scale(.9);
    z-index: -1;
}

.appContent ul li a:hover::before {
    transition: 1s;
    transform: scale(1.1);
    box-shadow: 0 0 15px red;
}

.btnSubmitFooter:hover {
    background-color: white;
    color: red;
    transition: 1s;
    border: 1px solid red;
}

.appContent ul li a:hover {
    color: red;
    box-shadow: 0 0 5px red;
    text-shadow: 0 0 5px red;
    transition: 1s;
}

.abuContent a:hover {
    color: red;
    text-shadow: 0 0 5px red;
    transition: 1s;
}

.abuEmail input {
    width: 336px;
    height: 54px;
    border-radius: 4px;
    background: #25333F;
    border: none;
    padding-left: 20px;
    margin-right: 5px;
}

.abuEmail button {
    width: 110px;
    height: 54px;
    border-radius: 4px;
    background: #E03232;
    border: none;
    color: white;
}

.socialMedia {
    width: 34px;
    height: 34px;
    background-image: url(../../../../public/img/backblack.svg);
    background-repeat: no-repeat;
    position: relative;
    margin-top: 16px;
}

.socialMedia img {
    left: 11px;
    position: absolute;
    top: 9px;
}

@media (max-width: 1440px) {
    .footerAll {
        margin: 0 20px;
    }
}

@media (max-width: 1076px) {
    .nameFooter {
        margin: 68px 0 16px 0;
    }
}

@media (max-width: 1076px) {
    .footer {
        padding: 0 48px 20px 48px;
    }
}

@media (max-width: 600px) {
    .abuEmail button {
        margin-top: 10px;
    }

    .abuEmail input {
        width: 100%;
    }
}

@media (max-width: 478px) {
    .infoFooter {
        max-width: 266px;
    }
}

@media (max-width: 378px) {
    .infoFooter {
        max-width: 209px;
    }
}

@media (max-width: 320px) {
    .infoFooter {
        max-width: 209px;
    }
}