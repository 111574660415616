.ourOffice {
    margin-bottom: 114px;
}

.office {
    max-width: 1116px;
    margin: 130px auto 0 auto;
}

.officeTitle {
    color: var(--black, #212121);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.back {
    width: calc((100% *4)/3);
    height: 343px;
    border: 1px solid #E2E2E2;
    background: #FFF;
    text-align: center;
    gap: 20px;
}

.officeItem {
    -webkit-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;
    height: 100%;
    background-image: none;
}

.officeItem:hover {
    opacity: 0.7;

    background-color: black;
}

.back:nth-child(1):hover {
    background-image: url('../../../../public/img/officeback1.jpg');
    background-size: cover;

}

.back:nth-child(2):hover {
    background-image: url('../../../../public/img/officeback2.jpg');
    background-size: cover;
}

.back:nth-child(3):hover {
    background-image: url('../../../../public/img/officeback3.jpg');
    background-size: cover;
}



.officeItem:hover .officeIcon img {
    filter: invert(168);
    transition: 1s;
}

.officeItem:hover .officeTitle1,
.officeItem:hover .officeInfo,
.officeItem:hover .officeNumber {
    color: white;
    transition: 1s;
}

.officeAll {
    display: flex;
    justify-content: space-evenly;
    gap: 18px;
}

.officeIcon {
    /* margin: 51px 0 36px 0; */
    height: 153px;
}

.officeIcon img {
    margin-top: 51px;
}

.officeTitle1 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    max-width: 303px;
    margin: auto auto 13px auto;
}

.officeInfo {
    max-width: 315px;
    width: 100%;
    color: var(--body, #4A4A4A);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    margin: 0 auto 17px auto;
}

.officeNumber {
    color: var(--red, #F00);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}


@media (max-width: 1440px) {

    .officeTitle,
    .redline {
        padding-left: 40px;
    }
}

@media (max-width: 1296px) {
    .back {
        margin-bottom: 20px;
    }
}

@media (max-width: 769px) {
    .officeAll {
        flex-wrap: wrap;
    }

    .back {
        width: 100%;
        margin: 0;
    }

    .office {
        margin-top: 50px;
    }
}

@media (max-width: 600px) {
    .officeTitle {
        font-size: 25px;
    }
}

@media (max-width: 320px) {
    .office {
        margin: 24px auto 0 auto;
    }

    .ourOffice {
        margin-bottom: 0;
    }
}