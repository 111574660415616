.aboutus {
    width: 100%;
    height: 659px;
    background: #F5F8FC;
    margin-top: 115px;
    background: lightgray url('/public/img/background.svg') no-repeat -72px -26.16px / 105% 107.41%;
    background-position: center;
    background-size: cover;
}

.redline {
    width: 75px;
    height: 4px;
    border-radius: 6px;
    background: var(--Red, #F00);
    margin: 16px 0 65px 0;
}

.abu {
    width: 100%;
    margin: auto;
}

.abuTitle {
    color: var(--black, #212121);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    padding: 70px 0 0 0;
    max-width: 350px;
}

/* css about us item */

.abuItem {
    width: 100%;
    display: flex;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 2px 8px 14px 0px rgba(0, 0, 0, 0.08);
}

.abuRedTick {
    max-height: 50px;
    max-width: 41px;
    font-family: 'Inter', sans-serif;
    color: var(--red, #F00);
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    margin: 0 31px 0 42px;
}

.abuStar {
    margin: 51px 0px 8px 0px;
}

.abuStar img {
    max-width: 92.143px;
    height: 15px;
    margin-left: 0;
}

.abuInfo {
    max-width: 400px;
    color: var(--black, #212121);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 41px 0 32px 0;
    height: 110px;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.abuPerson {
    display: flex;
    margin-bottom: 44px;
}

.abuFace {
    max-width: 65px;
    max-height: 65px;
    flex-shrink: 0;
    border-radius: 65px;
    margin-right: 24px;
}

.abuName {
    color: var(--black, #212121);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.abuPersonInfo p {
    color: var(--red, #F00);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

.sbuSlicker .mySwiper.swiper-initialized {
    height: 340px;
}

.sbuSlicker .swiper-button-prev,
.sbuSlicker .swiper-button-next {
    width: 54px;
    height: 54px;
    top: 50%;
    transform: translateY(-50%);
}


.sbuSlicker .swiper-button-prev:after,
.sbuSlicker .swiper-button-next::after {
    color: #3F3F46;
    width: 100%;
    font-size: 20px;
    background: #fff;
    box-shadow: 2px 8px 12px -2px rgba(0, 0, 0, 0.31);
    -webkit-box-shadow: 2px 8px 12px -2px rgba(0, 0, 0, 0.31);
    -moz-box-shadow: 2px 8px 12px -2px rgba(0, 0, 0, 0.31);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sbuSlicker .swiper-pagination-bullet {
    background-color: #D9D9D9 !important;
    opacity: 1 !important;
}

.sbuSlicker .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #E03232 !important;
}


.abuFace img {
    max-width: 65px;
    max-height: 65px;
    border-radius: 50px;
}


@media (max-width: 1440px) {

    .abuTitle,
    .redline {
        margin-left: 40px;
    }
}

@media (max-width: 1100px) {
    .abuItem {
        display: block;
        text-align: center;
    }

    .abuInfo {
        margin: 24px auto 25px auto;
    }

    .abuPerson {
        display: block;
    }

    .abuRedTick {
        margin: auto;
    }

    .abuFace {
        margin: auto;
    }

    .abuPersonInfo {
        padding-bottom: 20px;
    }

    .sbuSlicker {
        padding: 0 40px;
    }

    .sbuSlicker .swiper-button-prev,
    .sbuSlicker .swiper-button-next,
    .swiper-pagination {
        display: block;
    }
}

@media (max-width: 769px) {
    .sbuSlicker .mySwiper.swiper-initialized {
        height: 415px;
    }
}

@media (max-width: 601px) {
    .abuTitle {
        font-size: 25px;
    }

    .prev-slide,
    .next-slide {
        display: none;
    }

    .abuItem {
        display: block;
        text-align: center;

    }

    .abuInfo {
        margin: 24px auto 25px auto;
        font-size: 14px;
    }

    .abuPerson {
        display: block;
    }

    .abuRedTick {
        margin: auto;
    }

    .abuFace {
        margin: auto;
    }

    .abuPersonInfo {
        padding-bottom: 20px;
    }

    .sbuSlicker {
        padding: 0 15px;
    }

}

@media (max-width: 480px) {



    .aboutus {
        height: 700px;
    }

    /* .abuPerson {
        margin-top: 75px;
    } */

}


@media (max-width: 320px) {
    .aboutus {
        margin-top: 39px;
    }
}