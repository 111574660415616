.titleCilent {
    color: var(--black, #212121);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
}

.sliderCilent {
    height: 233px;
    width: 100%;
    margin: auto;
    /* box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10); */

}


.sliderCilent .owl-carousel .owl-dots {
    display: block !important;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.sliderCilent .owl-carousel .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

.sliderCilent .owl-carousel .owl-dots .owl-dot.active span,
.sliderCilent .owl-carousel .owl-dots .owl-dot:hover span {
    background: red !important;
}

.sliderCilent .owl-carousel .owl-dots .owl-dot span,
.sliderCilent .owl-carousel .owl-dots .owl-dot:hover span {
    background: #869791;
}



.imgCilent {
    gap: 104px;
    margin: auto;
}

.imgCilent .img1 {
    width: 202px;
    height: 121px;
    margin: auto;
    object-fit: contain;
    position: relative;
    display: flex;
}

.ilogo0 {
    position: absolute;
}

.imgCilent .img2 {
    width: 202px;
    height: 121px;
    margin: auto;
    object-fit: contain;
    display: flex;
}

.imgLogo1,
.imgLogo2 {
    width: auto !important;
    margin: auto;
}

.logoCustomer {
    display: flex;
    flex-wrap: wrap;
    max-width: 1140px;
    margin: auto;
    gap: 61px;
}

.logoCustomerAll {
    margin: auto;

}

@media(max-width: 600px) {
    .titleCilent {
        font-size: 25px;
    }
}