.contactAll {
    position: relative;
    height: 278px;
}

.Contact {
    max-width: 1117px;
    max-height: 394px;  
    margin: auto;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 2px 4px 28px 0px rgba(0, 0, 0, 0.10);
    display: flex;
}

.titleContact {
    color: var(--Black, #212121);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    margin: 74px 0 0 65px;
}

.redContact {
    width: 75px;
    height: 4px;
    border-radius: 8px;
    background: var(--Red, #F00);
    margin: 14px 0 40px 65px;
}

.infoContact {
    width: 284px;
    color: var(--body, #4A4A4A);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin: 0 102px 124px 65px ;
}

.infoContact span {
    color: var(--Red, #F00);
}

.fullName {
    margin: 44px 0 0 0;
}

.fullName input {
    padding: 10px 400px 10px 18px;
    border-radius: 4px;
    border: 1px solid var(--gray, #7C7C7C);
    opacity: 0.3;
}

.addressPut {
    margin: 20px 0 0 0;
}

.addressPut input:first-child {
    margin-right: 34px;
}

.addressPut input {
    border-radius: 4px;
    border: 1px solid var(--gray, #7C7C7C);
    opacity: 0.3;
    padding: 10px 88px 10px 18px;
}

.helpText {
    margin: 20px 0 32px 0;
}

.helpText textarea {
    border-radius: 4px;
    border: 1px solid var(--gray, #7C7C7C);
    opacity: 0.3;
    padding: 10px 404px 34px 18px;
}

.btnSubmit button {
    width: 121px;
    height: 50px;
    border-radius: 4px;
    background: var(--Red, #F00);
    color: white;
}

.btnSubmit button img {
    padding-left: 5px;
}

