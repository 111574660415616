body {
    margin: 0;
    font-family: 'Quicksand', sans-serif !important;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

/* Header menu css */
.dropdown-choose-lang {
    background: transparent !important;
    color: #212121 !important;
    border: unset !important;
}


.headerMenu {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}

.menuLogo {
    display: flex;
}

.menuLogo img {
    margin: auto;
    max-width: 146px;
    max-height: 45px;
}

.logoHeader {
    margin-left: 64px;
}

.logoUl {
    display: flex;
    margin: auto 0 auto 64px;
    gap: 52px;
    list-style: none;
}

.menuLogo ul li a {
    text-decoration: none;
    color: black;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.menuBtn {
    display: flex;
    margin: auto 0;
}

.menuBtn .dropdown {
    margin: auto 32px;
}

.menuBtn .dropdown img {
    transform: translateY(-8%);
    padding: 2px;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.btn:active {
    border-color: white !important;
}

.dropdown .american {
    width: 22px;
    height: 22px;
    border-radius: 22px;
    margin-right: 8px;
}

.dropdown a {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

.menuBtn .btn button {
    padding: 12px 28px;
    border-radius: 4px;
    background: var(--red, #F00);
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    border: none;
}

.menuBtn .btn button:hover {
    background-color: white;
    color: red;
    transition: 1s;
    border: 1px solid red;
}

.sparkle {
    color: var(--body, #4A4A4A) !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: red !important;
    text-shadow: 0 0 5px red;
    transition: 1s;
}

.demo-button {
    background: #E03232 !important;
    border: unset !important;
    padding: 10px 28px !important;
    font-size: 15px !important;
}

/* Header menu slider */


.sliderSwiper img {
    width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
    color: white;
}

.swiper-pagination-bullet {
    background-color: white !important;
}

.captionBox {
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    padding: 40px;
    max-width: 546px;
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    opacity: 0.9;
    text-align: left !important;
}


.titleCaption {
    color: var(--red, #F00);
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    max-width: 400px;
}

.captionBox p {
    max-width: 424px;
    color: var(--body, #4A4A4A);
    text-align: justify;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-top: 15px;
}

.bg-body-tertiary {
    background-color: white !important;
}

.infoHeaderMenu-wraper {
    background: #13222F;
}

.infoHeaderMenu {
    height: 44px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.inforightmenu,
.infoleftmenu {
    display: flex;
    align-items: center;
}

.redbug {
    width: 3px;
    height: 16px;
    background: var(--Red, #F00);
    margin: auto 7px auto 0;
}

.titlebug {
    color: var(--White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}


.mailmenu {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.mailmenu a {
    text-decoration: none;
    color: #EDEDED;
}

.phonemenu {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-left: 30px;
}

.phonemenu a {
    text-decoration: none;
    color: #EDEDED;
}

.mailmenu a:hover,
.phonemenu a:hover {
    color: red;
    text-shadow: 0 0 5px red;
    transition: 1s;
}

.fidAll,
.fidAll2,
.fidAll3,
.fidAll4 {
    display: flex;
}

.modal-dialog {
    max-width: 736px !important;
}

.modal-content {
    max-width: 736px !important;
}

.fidd {
    padding: 14px;
}

.fidd>div>div>input {
    height: 52px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding: 0 15px;
    width: 100%;
}

.fidd>div>div>input::placeholder {
    color: #B2B2B2;
}

.fidd>div {
    justify-content: space-between;
    display: flex;
    gap: 20px;
    justify-content: center;
}

.fidd>div>div {
    width: 50%;
}

.fidd>div>.MuiOutlinedInput-root,
.fidAll5 .btnRegister {
    width: 100%;
}

.Date .MuiFormControl-root {
    width: 100%;
    border-color: #E1E1E1;
}

.fidd .fidAll6 {
    display: block;
}

.Date .MuiFormControl-root .MuiOutlinedInput-root {
    height: 52px;
    border-color: #E1E1E1;
}

.fidd div p {
    margin: 30px 0 10px 0;
}

.fidAll6 select {
    width: 316px;
    height: 52px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
    margin: 0 20px 0 17px;
    color: #B2B2B2;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding-left: 18px;
    padding-right: 15px;
}


.btnRegister {
    height: 52px;
    border-radius: 6px;
    background: var(--Red, #E03232);
    margin: 40px 0 20px;
    color: white;
    border: none !important;
}

.btnRegister input {
    margin: 0 !important;
    width: 100% !important;
    background: var(--Red, #E03232);
    color: white !important;
}

.fidAll6 .dropdown,
.fidAll6 div .btn-light {
    width: 100% !important;
    height: 52px;
}



/* thanh cuộn */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

/* thanh cuộn */

.form-check-input {
    width: 20px !important;
    height: 20px !important;
    padding-left: 0px !important;
}

.dropdown-item {
    height: 43px !important;
}

.btn-group {
    height: 42px;
    width: 632px !important;
    border: solid 1px black;
    margin: 11px;
}

.actions-btn {
    margin-right: 0px !important;
    height: 40px !important;
}

.form-check-input {
    margin-top: 7px !important;
}

.actions-btn {
    margin-left: 0 !important;
}

.dropdown-item:active {
    color: white !important;
    text-decoration: none;
    background-color: red !important;
    text-shadow: 0 0 5px red !important;
    transition: 1s;
}

.form-check-input:checked {
    background-color: red !important;
    text-shadow: 0 0 5px red !important;
    border-color: red !important;
}

.form-check-label {
    padding-top: 5px !important;
}

.headerSlider .swiper-pagination {
    display: none;
}

li.MuiButtonBase-root {
    padding: 15px;
}

li.MuiButtonBase-root.Mui-selected:before {
    content: '\2713';
    margin-right: 5px;

}

@media (min-width: 1536px) {
    .container {
        max-width: 1450px !important;
    }

    .captionBox {
        left: calc((100% - 1450px) / 2);
    }
}

@media (max-width: 1400px) {

    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
        display: none;
    }
}

@media (max-width: 1200px) {
    .redColumn {
        top: 30px;
        left: 30px;
    }
}

@media (max-width: 992px) {

    .navbar-toggler {
        border: none !important;
    }

    .logoHeader.navbar button.navbar-toggler {
        padding: 0;
    }

    .dropdown {
        position: absolute !important;
        right: 0;
        top: 50px;
        z-index: 100;
    }

    .menuLogo img {
        order: 1;
        position: absolute;
        left: 50%;
        top: 45px;
        transform: translateX(-50%);
        scale: 0.7;
        margin-left: -15px;
    }

    .logoHeader {
        margin-left: 0;
    }

    .logoUl {
        margin-top: 25px;
        gap: 20px;
        margin-left: 20px;
    }

    .navbar-nav a {
        margin-left: 0;
    }

    .headerMenu {
        padding: 0;
    }

    .demo-button {
        font-size: 0 !important;
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 100;
        width: 50px;
        height: 50px;
        display: flex !important;
        align-items: center;
        padding: 5px !important;
        border-radius: 50% !important;
        overflow: hidden;
        justify-content: center;
    }

    .demo-button::before {
        content: '';
        background-image: url('/public/img/schedule-white.svg');
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

    }

    .menuBtn .dropdown {
        margin: 0;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 100% !important;
    }

    /* .btn {
        margin-right: 5px;
    }

    .menuLogo img {
        max-width: 110px;
        top: 57px !important;
    } */

    .modal-dialog {
        max-width: 90% !important;
        margin: 15px auto !important;
    }

    .btnRegister {
        width: 313px;
    }

    .captionBox {
        scale: 0.8;
        left: 0;
    }

    .fidAll,
    .fidAll2,
    .fidAll3,
    .fidAll4 {
        display: block;
    }

    .fidAll6 div .btn-light {
        width: 90% !important;
    }

    .btn-group {
        width: 93% !important;
    }

    .dropdown-menu {
        width: 90% !important;
    }

    .redColumn {
        scale: 0.8;
        top: 15px;
        left: 69px;
    }
}

@media (max-width: 600px) {

    .inforightmenu {
        display: none;
    }

    /* .dropdown {
        padding: 18px 0;
    } */

    .captionBox {
        scale: 1;
        padding: 20px 20px !important;
        left: 0;
        top: 15px;
        display: none;
    }

    .redColumn {
        display: none;
    }

    .titleCaption {
        font-size: 27px;
    }

}

@media (max-width: 480px) {

    .fidd>div {
        flex-wrap: wrap;
        gap: 0;
    }

    .fidd>div>div {
        width: 100%;
    }

    .fidd div input {
        max-width: 316px;
        width: 90%;
    }

    .btnRegister {
        max-width: 313px;
        width: 90%;
    }

    .menuBtn .btn button {
        padding: 12px 7px;
    }

    .captionBox {
        padding: 10px 20px !important;
        height: auto;
        width: 430px;
    }

    .fidd div p {
        margin-top: 10px;
    }

}

@media (max-width: 320px) {

    .form-check-label {
        font-size: 12px !important;
    }

    .menuBtn .dropdown {
        margin: auto 10px;
    }

    .captionBox p {
        margin-top: 0;
    }

    .menuLogo img {
        left: 52px;
    }

    .fidd div input {
        margin: 0 0 0 17px;
    }

    .form-check-label {
        padding-top: 8px !important;
    }
}